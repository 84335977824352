import { Button } from "@heroui/button";
import { Image } from "@heroui/image";
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

type Props = {};

const Footer = (props: Props) => {
    const t = useTranslations('layout');

    return (
        <footer className={'text-gray-800 pt-12 pb-5 px-4'}>
            <div className='max-w-screen-xl mx-auto'>
                {/* footer head */}
                <div className='w-full py-10 flex flex-wrap gap-4 justify-between items-center border-t border-gray-200'>
                    <div className='flex items-center gap-4'>
                        <Link aria-label='Home' href='/'>
                            <span className='text-xl text-primary font-bold'>
                                <Image
                                    // as={NextImage}
                                    alt='vnlogi-logo'
                                    radius='none'
                                    loading='lazy'
                                    src='/assets/images/VNLOGI_logo-cropped.svg'
                                    className='w-full h-8'
                                />
                            </span>
                        </Link>

                        <div className='flex flex-col text-xs font-normal'>
                            <span>2024 © VNLogi.</span> <span>All rights reserved.</span>{' '}
                        </div>
                    </div>
                    <div className='flex items-center gap-6 flex-wrap'>
                        <div className='flex items-center gap-4 flex-wrap'>
                            {/* <Button isIconOnly className='!bg-gray-100'>
                                <FaLinkedinIn size={18} />
                            </Button> */}
                            <Button aria-label='facebook' isIconOnly className='!bg-gray-100'>
                                <FaFacebook size={18} />
                            </Button>
                            {/* <Button isIconOnly className='!bg-gray-100'>
                                <FaSquareInstagram size={18} />
                            </Button> */}
                            <Button aria-label='youtube' isIconOnly className='!bg-gray-100'>
                                <FaYoutube size={18} />
                            </Button>
                            {/* <Button isIconOnly className='!bg-gray-100'>
                                <FaXTwitter size={18} />
                            </Button> */}
                        </div>
                        {/* <div className='flex items-center gap-2'>
                            <div className='flex items-center justify-center rounded-xl w-32 h-10 bg-gray-800 hover:bg-gray-950 cursor-pointer'>
                                <Image
                                    src='https://www.searates.com/design/images/footer/appStore.svg'
                                    width='90'
                                    height='23'
                                    alt=''
                                />
                            </div>

                            <div className='flex items-center justify-center rounded-xl w-32 h-10 bg-gray-800 hover:bg-gray-950 cursor-pointer'>
                                <Image
                                    src='https://www.searates.com/design/images/footer/googlePlay.svg'
                                    width='90'
                                    height='23'
                                    alt=''
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* footer head */}

                {/* footer nav */}
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5 gap-8 lg:gap-0'>
                    <div className='flex flex-col gap-3'>
                        <span className='text-sm font-semibold'>{t('tools')}</span>
                        <div className='flex flex-col gap-2.5'>
                            <span className='footer-item'>{t('logisticsExplorer')}</span>
                            <span className='footer-item'>{t('containerTracking')}</span>
                            <span className='footer-item'>{t('airTracking')}</span>
                            <span className='footer-item'>{t('shipSchedules')}</span>
                            <span className='footer-item'>{t('loadCalculator')}</span>
                            <span className='footer-item'>{t('logisticsMap')}</span>
                            <span className='footer-item'>{t('distanceAndTime')}</span>
                            <span className='footer-item'>{t('routePlanner')}</span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='text-sm font-semibold'>{t('opportunities')}</span>
                        <div className='flex flex-col gap-2.5'>
                            <span className='footer-item'>{t('forShipper')}</span>
                            <span className='footer-item'>{t('forCarriers')}</span>
                            <span className='footer-item'>{t('integrations')}</span>
                            <span className='footer-item'>{t('api')}</span>
                            <span className='footer-item'>{t('devPortal')}</span>
                            <span className='footer-item'>{t('requestItTool')}</span>
                            <span className='footer-item'>{t('affiliates')}</span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='text-sm font-semibold'>{t('resource')}</span>
                        <div className='flex flex-col gap-2.5'>
                            <span className='footer-item'>{t('findFreightRoutes')}</span>
                            <span className='footer-item'>{t('shippingLinesDirectory')}</span>
                            <span className='footer-item'>{t('maritime')}</span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='text-sm font-semibold'>{t('legal')}</span>
                        <div className='flex flex-col gap-2.5'>
                            <span className='footer-item'>{t('termOfService')}</span>
                            <span className='footer-item'>{t('privacyPolicy')}</span>
                            <span className='footer-item'>{t('copyright')}</span>
                            <span className='footer-item'>{t('docAndTemplate')}</span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <span className='text-sm font-semibold'>{t('company')}</span>
                        <div className='flex flex-col gap-2.5'>
                            <span className='footer-item'>{t('contact')}</span>
                            <span className='footer-item'>{t('blog')}</span>
                            <span className='footer-item'>{t('careers')}</span>
                            <span className='footer-item'>{t('help')}</span>
                            <span className='footer-item'>{t('companyPhone')}</span>
                        </div>
                    </div>
                </div>
                {/* footer nav */}

                {/* footer description */}
                <div className='py-10 text-xs font-normal mt-4'>{t('webDescription')}</div>
                {/* footer description */}
            </div>
        </footer>
    );
};

export default Footer;
