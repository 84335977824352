import { ReactNode } from 'react';
import Footer from './Footer';
import Header from './Header';

type Props = {
    children?: ReactNode;
    hiddenFooter?: boolean;
};

export default function MainLayout({ children, hiddenFooter = false }: Props) {
    return (
        <div className='bg-gray-100'>
            <Header />
            <main className='mx-auto max-w-screen-xl px-4 py-6 md:py-10'>{children}</main>
            {!hiddenFooter && <Footer />}
        </div>
    );
}
